/// <reference path="../typings/index.d.ts" />
/// <reference path="index.d.ts" />
interface JQueryStatic {
  cookie(key: string);

  cookie(key: string, value: string, options?: Object);
}

var tracker: TrackerAnalytics;

class TrackerAnalytics {
  private _categoryGeneral: string;

  constructor(campaign?: string) {
    this._categoryGeneral = campaign;
    this.parseLinks();
  }

  parseLinks(): void {
    $('[data-event]').on('click', (evt, noEvent = false) => {
      if (!$(evt.currentTarget).data('event-manual') && !noEvent) {
        this.sendEvent($(evt.currentTarget));
      }
    });
  }

  sendEvent(elm: JQuery): void {
    var category: string;
    var action: string;
    var label: string;
    if (elm.data('event') == 'self') {
      label = elm.text();
    } else if (elm.data('event') == 'selector') {
      label = $(elm.data('event-selector'), elm).text();
    } else {
      label = elm.data('event');
    }


    if (elm.data('event-category')) {
      category = elm.data('event-category');
    } else if (elm.parent().data('event-category')) {
      category = elm.parent().data('event-category');
    } else if ($('body').data('event-category')) {
      category = $('body').data('event-category');
    } else {
      category = this._categoryGeneral;
    }

    if (elm.data('event-action')) {
      action = elm.data('event-action');
    } else if (elm.parent().data('event-action')) {
      action = elm.parent().data('event-action');
    } else {
      action = $('body').data('event-action');
    }

    this.sendEventCustom(category, action, label)
  }

  sendEventCustom(category: string, action: string, label: string): void {
    ga('send', 'event', category, action, label);
  }

  sendPage(url: string): void {
    ga('send', 'pageview', url);
  }

}

class Formulario {
  validator;
  itemCategory = $('.item-categories');
  btnConnect = $('.btn-connect');

  constructor() {
    this.userEvents();
    this.initForms();
  }

  allActions() {

  }

  loggedFacebook() {
    FB.api('/me?fields=email,gender,birthday,first_name,last_name', function (response) {
      var sendForm = true;

      $("#txt-nomape").val(response['first_name'] + ' ' + response['last_name']);
      if (response['email']) {
        $("#txt-email").val(response['email']);
      } else {
        sendForm = false;
      }

      if (response['birthday']) {
        var birthday = response['birthday'].split('/');
        $("#txt-day").val(birthday[0]);
        $("#txt-month").val(birthday[1]);
        $("#txt-year").val(birthday[2]);
      } else {
        sendForm = false;
      }

      if (response['gender']) {
        if (response['gender'] == 'male') {
          $("input[name='gender']").prop('checked', false);
          $("#male").prop('checked', true);
        } else if (response['gender'] == 'female') {
          $("input[name='gender']").prop('checked', false);
          $("#female").prop('checked', true);
        }
      } else {
        sendForm = false;
      }
      if (sendForm) {
        $('#check_terms').prop('checked', true);
        $('#check_privacy').prop('checked', true);
        $("button.btn-suscribe").trigger("click");
      } else {
        this.messageErrorFacebook();
      }
    });
  }

  userEvents() {

    this.itemCategory.on('click', (evt) => {
      $(evt.currentTarget).toggleClass('selected');

      if (this.itemCategory.hasClass('selected')) {
        $('.error-message').html('');
        $('.error-message-form').html('');
      }
      $('#category').val(this.selectedCategories());

    });

    this.btnConnect.on('click', (evt) => {
      evt.preventDefault();

      var categories = $('#category').val();

      if (categories == '') {
        this.messaggeErrorCategories();
      } else {
        FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            this.loggedFacebook();
          } else {
            if (navigator.userAgent.match('CriOS')) {
              window.open('https://www.facebook.com/dialog/oauth?client_id=696489247201930&redirect_uri=' + document.location.href + '&scope=email,public_profile', '', null);
            } else {
              FB.login((response) => {
                if (response.status === 'connected') {
                  this.loggedFacebook();
                } else {
                }
              }, {scope: 'public_profile,email'});
            }
          }
        });
      }

    });

    //Validación formulario
    $("button.btn-suscribe").on("click", (evt) => {
      evt.preventDefault();
      this.validator.settings.showFirstValidation = true;

      if (this.itemCategory.hasClass('selected')) {

        this.selectedCategories();
        if ($(".form-suscribe").valid()) {

          $(evt.currentTarget).attr('disabled', 'true');

          var data = $(".form-suscribe").serialize();

          $.ajax({
            url: '',
            method: "POST",
            data: data,
            success: (response) => {
              $(evt.currentTarget).removeAttr('disabled');

              if (response.success) {
                tracker.sendEvent($('.form-suscribe button.btn-suscribe'));
                tracker.sendEventCustom($('body').data('event-category'), 'clic_opcion', $('#category').val().replace(',', '-'));
                window.location.href = 'gracias.html';
              } else {
                if (response.errors.hasOwnProperty('email')) {
                  $('#txt-email').addClass('error');
                  $('#txt-email').parent().append('<label id="txt-email-error" class="error" for="txt-email">Email ya registrado</label>');
                  var position = $('.email-label').offset();
                  $(body).stop().animate({
                    scrollTop: position.top
                  });
                }
              }
            }
          });

        } else {
          var position = $('.form-container form').offset();

        }
      } else {
        //Todo: mostrar error de seleccion categorias
        this.messaggeErrorCategories();
      }

    });

  }

  selectedCategories() {
    var categories = [];

    this.itemCategory.each(function (index, elem) {
      if ($(elem).hasClass('selected')) {
        categories.push($(this).data('cat'));
      }
    });
    return categories.join(',');
  }

  messaggeErrorCategories() {
    $('.error-message').html('Selecciona una o varias categorias');
    $('.error-message-form').html('Debes llenar todos los datos del formulario.');
  }

  messageErrorFacebook() {
    $('.error-message-facebook').html('Completa tus datos personales');
  }

  initForms() {
    // Formulario.submitted = true;
    $('form .numeric').numeric({});
    $('form .alphanum').alphanum();
    $('form .alpha').alpha();
    $('form .email').alphanum({
      allow: '.-_@',
      disallow: '°¡¨´¿'
    });

    $.validator.messages['txt-email'] = 'Ingresa un correo electrónico válido';

    $.validator.addMethod('checkMail', (value, element) => {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\")){2,}@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value);
    }, $.validator.messages['txt-email']);

    this.validator = $('.form-suscribe').validate({
      onfocusout: false,
      showFirstOnly: true,
      showFirstValidation: false,
      forceShowError: false,
      forceShowRemoteError: false,
      groups: {
        birthdate: "txt-day txt-month txt-year"
      },
      errorPlacement: function (error, element) {
        if (element.hasClass('select-date')) {
          $('.form-birthdate').append(error);
        } else if (element.attr('id') == 'check_terms') {
          $('.box-terms').append(error);
        } else if (element.attr('id') == 'check_privacy') {
          $('.box-privacy').append(error);
        } else {
          error.insertAfter(element);
        }
      },
      showErrors: function (errorMap, errorList) {
        $('.form-suscribe label.error').remove();
        if (this.settings.forceShowRemoteError) {
          this.defaultShowErrors();
          this.settings.forceShowRemoteError = false;
        } else {
          if (this.settings.showFirstValidation) {
            $('.error').removeClass('error');
            if (errorList.length == 0 || this.settings.forceShowError) {
              this.defaultShowErrors();
              this.settings.forceShowError = false;
            } else if (this.settings.showFirstOnly) {
              if (errorList.length) {
                // this.resetForm();
                this.settings.forceShowError = true;
                this.element(errorList[0].element);
              }
            }
          }
        }
      }
    });
  }

}

interface ISliderBasicDomElms {
  itemsDom: JQuery;
  containerDom: JQuery;
  buttonPrevDom: JQuery;
  buttonNextDom: JQuery;
}

interface ISliderBasicElms {
  context?: string;
  items: string;
  container: string;
  buttonPrev?: string;
  buttonNext?: string;
  updateCallback?: Function;
}

class SliderBasic {
  position: number = 0;
  limitMax: number;
  contextDom: string | Document;
  context: string;
  items: string;
  container: string;
  buttonPrev: string;
  buttonNext: string;
  updateCallback: Function;
  domElms: ISliderBasicDomElms;

  // items

  constructor(obj: ISliderBasicElms) {
    if (obj.context) {
      this.contextDom = obj.context;
    } else {
      this.contextDom = document;
    }
    this.items = obj.items;
    this.container = obj.container;
    this.buttonPrev = obj.buttonPrev;
    this.buttonNext = obj.buttonNext;
    this.updateCallback = obj.updateCallback;
    this.initializeDomElms();
    this.initialize();
    this.userEvents();
    this.moveElements();
  }

  initializeDomElms() {
    // if(this.)
    this.domElms = {
      itemsDom: $(this.items, this.contextDom),
      containerDom: $(this.container, this.contextDom),
      buttonPrevDom: $(this.buttonPrev, this.contextDom),
      buttonNextDom: $(this.buttonNext, this.contextDom)
    }
  }

  initialize() {
    this.limitMax = this.domElms.itemsDom.length;
    this.limitMax = this.domElms.itemsDom.length;
    this.domElms.containerDom.css({
      width: this.limitMax * this.domElms.itemsDom.outerWidth()
    });
  }

  moveElements() {
    if (this.position >= this.limitMax) {
      this.position = this.limitMax - 1;
    }
    if (this.position < 0) {
      this.position = 0;
    }

    if (this.position == 0) {
      this.domElms.buttonPrevDom.addClass('inactive');
    } else {
      this.domElms.buttonPrevDom.removeClass('inactive');
    }

    if (this.position >= (this.limitMax - 1)) {
      this.domElms.buttonNextDom.addClass('inactive');
    } else {
      this.domElms.buttonNextDom.removeClass('inactive');
    }

    this.updateCallback(this.position);

    this.domElms.containerDom.css({
      left: this.position * this.domElms.itemsDom.outerWidth() * -1
    });
    console.log(this.position);
  }

  gotoPosition(pos) {
    this.position = pos;
    this.moveElements();
  }

  userEvents() {
    this.domElms.buttonNextDom.on('click', (evt) => {
      if (!this.domElms.containerDom.is(':animated')) {
        this.position++;
        this.moveElements();
      }
    });
    this.domElms.buttonPrevDom.on('click', (evt) => {
      if (!this.domElms.containerDom.is(':animated')) {
        this.position--;
        this.moveElements();
      }
    });
  }
}

class SliderUpdatable extends SliderBasic {
  constructor(obj: ISliderBasicElms) {
    super(obj);
  }

  reload(obj: ISliderBasicElms) {
    this.position = 0;

    if (obj.context) {
      this.contextDom = obj.context;
    }
    if (obj.items) this.items = obj.items;
    if (obj.container) this.container = obj.container;
    if (obj.buttonPrev) this.buttonPrev = obj.buttonPrev;
    if (obj.buttonNext) this.buttonNext = obj.buttonNext;
    if (obj.updateCallback) this.updateCallback = obj.updateCallback;
    this.initializeDomElms();
    this.initialize();
    this.userEvents();
    this.moveElements();
  }
}

class Modal {
  constructor() {
    this.initialize();
  }

  initialize() {
    $('.overlay-component .btn-close,.overlay-component .close-action').on('click', (evt) => {
      this.closeAll()
    });
    $('.overlay-component').on('click', (evt) => {
      this.closeAll()
    });
    $('.overlay-component .window').on('click', (evt) => {
      evt.stopPropagation();
    });
    $('.overlay-component .box-video-mobile').on('click', (evt) => {
      evt.stopPropagation();
    });
    $(document).keyup((e) => {
      if (e.keyCode == 27 && $('body').hasClass('modal-active')) {
        this.closeAll();
      }
    });
  }

  showModal(elm: string) {

    $('.overlay-component .window').removeClass('active');
    $('.overlay-component').addClass('active');
    $('.overlay-component .window.' + elm).addClass('active');
    $('body').addClass('modal-active');

  }

  closeAll() {

    var sel = $('.overlay-component .window.active');
    sel.trigger('beforeClose');
    $('.overlay-component .window').removeClass('active');
    $('.overlay-component').removeClass('active');
    $('body').removeClass('modal-active');
    sel.trigger('afterClose');

  }
}

class Pages {
  controller: ScrollMagic.Controller;
  sliderProjects: SliderBasic;
  gallery: SliderUpdatable;

  constructor() {

  }

  allActions() {
    // this.modalManager = new Modal();
    tracker = new TrackerAnalytics();

    $('.view-tc').on('click', (evt) => {
      // this.modalManager.showModal('tos');
    });

  }

  main() {

    this.gallery = new SliderUpdatable({
      context: '.overlay-component',
      items: '.slider-item',
      container: '.slider-content',
      updateCallback: (elm) => {
        $('.project-modal-slider .navigation-dots .dots').removeClass('active');
        $('.project-modal-slider .navigation-dots .dots').eq(elm).addClass('active');
      }
    });

    this.sliderProjects = new SliderBasic({
      context: '.overlay-component-projects',
      items: '.slider-item',
      container: '.slider-content',
      buttonPrev: '.slider-arrow.btn-slider.prev',
      buttonNext: '.slider-arrow.btn-slider.next',
      updateCallback: (elm) => {
        $('.overlay-component-projects .container').animate({scrollTop: 0}, 600);

        let galleryItems = '';
        let galleryDots = '';

        $('.project-detail-image img', $('.projects-detail .slider-item').eq(elm)).each((idx, elm) => {
          galleryItems += '<div class="slider-item"><img src="' + $(elm).data('src') + '"></div>';
          galleryDots += '<div class="dots"></div>';
        });

        $('.project-modal-slider .slider-content').html(galleryItems);
        $('.project-modal-slider .navigation-dots').html(galleryDots);

        this.gallery.reload({
          items: '.slider-item',
          container: '.slider-content'
        })
      }
    });


    $('.project-item').on('click', (evt) => {
      this.sliderProjects.gotoPosition($(evt.currentTarget).index());

      $('body').addClass('modal-active');
      $('.overlay-component-projects').addClass('active');

      let galleryItems = '';
      let galleryDots = '';

      $('.project-detail-image img', $('.projects-detail .slider-item').eq($(evt.currentTarget).index())).each((idx, elm) => {
        galleryItems += '<div class="slider-item"><img src="' + $(elm).data('src') + '"></div>';
        galleryDots += '<div class="dots"></div>';
      });

      $('.project-modal-slider .slider-content').html(galleryItems);
      $('.project-modal-slider .navigation-dots').html(galleryDots);

      this.gallery.reload({
        items: '.slider-item',
        container: '.slider-content'
      })
    });

    $('.project-detail-images').on('click', '.project-detail-image', (evt) => {
      $('body').addClass('modal-active');
      $('.overlay-component').addClass('active');
      this.gallery.gotoPosition($(evt.currentTarget).index());
    });

    $('.overlay-component-projects .btn-close-project').on('click', (evt) => {
      // console.log($(evt.currentTarget).index());
      $('body').removeClass('modal-active');
      $('.overlay-component-projects').removeClass('active');
    });

    $('.overlay-component .btn-close-project').on('click', (evt) => {
      // console.log($(evt.currentTarget).index());
      $('.overlay-component').removeClass('active');
    });
    $('.project-modal-slider .navigation-dots').on('click', '.dots', (evt) => {
      this.gallery.gotoPosition($(evt.currentTarget).index());
    });
    $('.toggle-button').on('click', (evt) => {
      $('#main-header').toggleClass('open');
    });
    // $('#main-header').on('click', (evt) => {
    //   $(evt.currentTarget).addClass('open');
    // });

    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 0.5
      }
    });

    this.controller.scrollTo(function (newScrollPos) {
      $("html, body").animate({scrollTop: newScrollPos}, 1000);
    });

    new ScrollMagic.Scene({
      triggerElement: '#home',
      duration: '100%'
    })
      .setClassToggle($('nav.menu a:eq(0)')[0], 'active')
      .addTo(this.controller)
      .on("enter", (evt: any) => {
      });

    new ScrollMagic.Scene({
      triggerElement: '#who-weare',
      duration: '100%'
    })
      .setClassToggle($('nav.menu a:eq(1)')[0], 'active')
      .addTo(this.controller)
      .on("enter", (evt: any) => {
      });

    new ScrollMagic.Scene({
      triggerElement: '#what-to-do',
      duration: function () {
        return $('#what-to-do').outerHeight()
      }
    })
      .setClassToggle($('nav.menu a:eq(2)')[0], 'active')
      .addTo(this.controller)
      .on("enter", (evt: any) => {
      });

    // new ScrollMagic.Scene({
    //   triggerElement: '#team',
    //   duration: '100%'
    // })
    //   .setClassToggle($('nav.menu a:eq(3)')[0], 'active')
    //   .addTo(this.controller)
    //   .on("enter", (evt: any) => {
    //   });

    new ScrollMagic.Scene({
      triggerElement: '#contact',
      duration: '100%'
    })
      .setClassToggle($('nav.menu a:eq(3)')[0], 'active')
      .addTo(this.controller)
      .on("enter", (evt: any) => {
      });

    $('a.go-section,a.logo').on('click', (evt) => {
      // evt.preventDefault();
      if ($(evt.currentTarget).data('url')) {
        var content = $(evt.currentTarget).data('url');
        this.controller.scrollTo($('section' + content).offset().top);
      }
      $('#main-header').removeClass('open');
      //$('body').removeClass('expanded');
    });

    $('.project-modal-slider .slider-elm').swipe({
      //Generic swipe handler for all directions
      allowPageScroll: "auto",
      swipe: (event, direction, distance, duration, fingerCount, fingerData) => {

        if (direction == "left") {
          $('.slider-elm .navigation-dots .dots.active').next().trigger('click');
        }
        if (direction == "right") {
          $('.slider-elm .navigation-dots .dots.active').prev().trigger('click');
        }
      }
    });

    $('.projects-detail .slider-elm').swipe({
      //Generic swipe handler for all directions
      allowPageScroll: "auto",
      swipe: (event, direction, distance, duration, fingerCount, fingerData) => {

        if (direction == "left") {
          $('.slider-elm .slider-arrow.next').trigger('click');
        }
        if (direction == "right") {
          $('.slider-elm .slider-arrow.prev').trigger('click');
        }
      }
    });

  }

}

class CommonController {

  constructor() {

  }

  allActions() {

  }
}

var Public = {
  initiatedClasses: {}
}

var UTIL = {
  exec: function (controller, action = 'allActions') {
    var controllerClass: any;
    if (window[controller]) {
      if (Public.initiatedClasses[controller]) {
        controllerClass = Public.initiatedClasses[controller];
      } else {
        var controllerClass = Object.create((<any>window[controller]).prototype);
        controllerClass.constructor.apply(controllerClass);
        Public.initiatedClasses[controller] = controllerClass;
      }
      if (controllerClass[action]) {
        controllerClass[action]();
      }
    }
  },
  init: function () {
    var body = $("body");
    var controller = body.data("router-class");
    var action = body.data("router-action");

    this.exec("CommonController");
    this.exec(controller);
    this.exec(controller, action);
  }
}

$(document).ready(function () {
  UTIL.init();
});
